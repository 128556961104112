html {
  -webkit-text-size-adjust: 100%;
  line-height: 1.15;
}

body {
  margin: 0;
}

main {
  display: block;
}

h1 {
  margin: .67em 0;
  font-size: 2em;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: monospace;
  font-size: 1em;
}

a {
  background-color: #0000;
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline dotted;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp {
  font-family: monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

img {
  border-style: none;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner, [type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring, [type="button"]:-moz-focusring, [type="reset"]:-moz-focusring, [type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  padding: .35em .75em .625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  max-width: 100%;
  white-space: normal;
  padding: 0;
  display: table;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type="checkbox"], [type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details {
  display: block;
}

summary {
  display: list-item;
}

template, [hidden] {
  display: none;
}

:root {
  --color-primary: #dc3545;
  --color-secondary: #6c757d;
  --color-light: #f8f9fa;
  --color-accent: #8a949c;
  --color-success: #28a745;
  --color-success-light: #2eb34d;
  --color-info: #007bff;
  --color-info-dark: #024d9c;
  --color-info-light: #4f9ff5;
}

*, :after, :before {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  font-size: 62.5%;
}

body {
  font-family: Inter, Arial, Helvetica, sans-serif;
  font-size: 2rem;
  line-height: 1.5;
}

h1, h2, h3, h4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
  line-height: 1.1;
}

h1 {
  text-align: center;
  font-size: 4rem;
}

h2 {
  text-transform: uppercase;
  text-align: center;
  margin: 5rem 0 6rem;
  font-size: 3rem;
}

h3 {
  font-size: 3rem;
}

h4 {
  font-size: 1.8rem;
}

@media screen and (min-width: 768px) and (max-width: 1200px) {
  h1 {
    text-align: center;
    font-size: 4rem;
  }
}

@media screen and (min-width: 1200px) {
  body {
    font-size: 1.8rem;
  }

  h1 {
    text-align: left;
    font-size: 6rem;
  }

  h2 {
    font-size: 4rem;
  }

  h3 {
    letter-spacing: 1px;
    font-size: 2.7rem;
  }
}

.block {
  padding: 5rem 2rem;
}

.block--dark {
  color: #fff;
  background: #000;
}

.block--light {
  color: #000;
  background: #f8f9fa;
}

.block--skewed-left {
  clip-path: polygon(-1% -1%, 100% -1%, 100% 90%, 0% 100%);
  padding-bottom: 10rem;
}

.block.block--last {
  margin-bottom: 0;
  padding-bottom: 15rem;
}

.container {
  max-width: 1140px;
  margin: 0 auto;
}

.countdown-timer {
  color: #fff;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
  font-size: 5rem;
  display: flex;
}

.countdown-timer__element {
  background: var(--color-primary);
  border-radius: 15px;
  padding: .5rem 2rem;
}

.countdown-timer__label {
  padding-left: .5rem;
  font-size: 1.5rem;
}

@media screen and (min-width: 768px) {
  .countdown-timer {
    font-size: 6rem;
  }

  .countdown-timer__label {
    font-size: 2rem;
  }
}

.grid {
  display: grid;
}

.grid--fit {
  align-items: start;
}

@media screen and (min-width: 768px) {
  .grid--1x2 {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (min-width: 1024px) {
  .grid--1x3 {
    grid-template-columns: repeat(3, 1fr);
  }
}

.card {
  background: #fff;
  border-radius: 7px;
  margin: 2rem;
  overflow: hidden;
  box-shadow: 0 5px 15px #00000059;
}

.card__header {
  font-size: 2rem;
}

.card__header--flex {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.card__module-number {
  text-transform: uppercase;
  margin-top: 2rem;
}

.card__module-name {
  text-transform: uppercase;
  text-align: center;
  font-weight: bold;
}

.card__icon {
  color: var(--color-info-dark);
  margin-right: 1rem;
  font-size: 3rem;
}

.card__header, .card__body {
  padding: 3rem 4rem;
}

.card--accent .card__header {
  background: var(--color-info-light);
  color: #fff;
}

.card--success .card__header {
  background: var(--color-success);
  color: #fff;
}

.card__image {
  width: 100px;
}

.card__list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.card__item {
  color: var(--color-secondary);
  padding-bottom: 1rem;
  font-size: 1.5rem;
  font-weight: bold;
}

.card__lesson {
  text-transform: uppercase;
  margin-right: .5rem;
  font-weight: normal;
}

@media screen and (min-width: 768px) {
  .card__header {
    font-size: 2.2rem;
  }
}

.menu {
  background: #000;
  padding: 0;
}

.menu__list {
  flex-direction: column;
  align-items: center;
  row-gap: 1.3rem;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}

.menu__link {
  color: #fff;
  color: var(--color-accent);
  text-decoration: none;
}

.menu__link:hover {
  color: var(--color-light);
}

.menu__icon {
  color: var(--color-accent);
  opacity: .5;
  cursor: pointer;
  border: 1px solid #fff;
  border-radius: 3px;
  margin: 1rem 1rem 1rem 2rem;
  padding: .5rem 1.5rem;
  font-size: 2rem;
  transition: box-shadow .15s;
}

.menu__icon:hover {
  color: var(--color-light);
}

.collapsible__content {
  max-height: 0;
  opacity: 0;
  transition: max-height .3s linear, opacity .3s linear;
  overflow: hidden;
}

.collapsible--expanded .collapsible__content {
  max-height: 100vh;
  opacity: 1;
}

.collapsible--expanded .menu__icon {
  opacity: 1;
  border-radius: 5px;
  box-shadow: 0 0 3px 2px #fff;
}

.collapsible__header {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.collapsible__arrow {
  cursor: pointer;
  color: var(--color-secondary);
  font-size: 2.7rem;
  transition: transform .3s linear;
  transform: rotate(0);
}

.collapsible--expanded .collapsible__arrow {
  transform: rotate(90deg);
}

@media screen and (min-width: 900px) {
  .menu__icon {
    display: none;
  }

  .menu__list {
    max-height: 100%;
    opacity: 1;
    flex-direction: row;
    justify-content: flex-end;
    column-gap: 2rem;
    margin: 0;
    padding: 2rem 0;
  }

  .menu__link {
    margin: 0;
  }

  .collapsible--expanded .collapsible__content {
    margin-bottom: 0;
  }

  .menu {
    padding: 0 5rem;
  }
}

.heading {
  padding: 1rem 0 7rem;
}

.heading_left, .heading__top {
  margin: 0 2rem;
}

.heading__top h1 {
  text-align: center;
}

.heading.heading--small-padding {
  padding-top: 5rem;
}

.heading__bottom {
  margin: 4rem;
}

.heading_right {
  margin: 7rem 4rem 4rem;
}

.heading_secondary-text {
  color: var(--color-accent);
  min-height: 90px;
  text-align: center;
  margin-top: 1rem;
  display: inline-block;
}

@media screen and (min-width: 768px) {
  .heading_left, .heading_right {
    margin: 0 3rem;
  }

  .heading {
    padding: 15rem 0;
  }
}

@media screen and (min-width: 1200px) {
  .heading_left, .heading_right {
    margin: 0 3rem;
  }

  .heading {
    padding: 15rem 0;
  }

  .heading_secondary-text {
    text-align: left;
    margin-top: 0;
  }
}

.start-course {
  padding: 8rem 0;
}

.start-course__container {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.start-course__btn {
  background: var(--color-success);
  text-transform: uppercase;
  color: #fff;
  text-align: center;
  cursor: pointer;
  border-radius: 15px;
  padding: 2rem 3.3rem;
  font-size: 4rem;
  font-weight: bold;
  text-decoration: none;
  display: block;
  box-shadow: 0 1px 2px #00000012, 0 2px 4px #00000012, 0 4px 8px #00000012, 0 8px 16px #00000012, 0 16px 32px #00000012, 0 32px 64px #00000012;
}

.start-course__btn:hover {
  background: var(--color-success-light);
}

.start-course__btn--info {
  background: var(--color-info);
}

.start-course__btn--info:hover {
  background: var(--color-info-light);
}

.start-course__label {
  text-transform: uppercase;
  text-align: center;
  margin: 3rem auto;
  font-size: 1.8rem;
  font-weight: bold;
  display: block;
}

.start-course__label-info {
  text-align: center;
  margin: 3rem auto;
  font-size: 1.9rem;
  display: block;
}

@media screen and (min-width: 768px) and (max-width: 1199px) {
  .start-course__btn {
    padding: 2rem;
    font-size: 5rem;
  }
}

@media screen and (min-width: 1200px) {
  .start-course__btn {
    padding: 2rem 5rem;
    font-size: 5rem;
  }

  .start-course__label {
    font-size: 2rem;
  }
}

.author__img {
  border-radius: 40%;
  align-self: center;
  margin: 0 auto;
}

.author__info {
  margin-top: 3rem;
  font-size: 1.6rem;
  line-height: 1.7;
}

.author__list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.author__item {
  padding-left: 4rem;
  position: relative;
}

.author__item:before {
  content: "➡";
  position: absolute;
  left: 1.2rem;
}

@media screen and (min-width: 1024px) {
  .author__info {
    grid-column: 2 / span 2;
    margin-top: 0;
    font-size: 1.6rem;
  }
}

.opinion-block {
  padding-bottom: 10rem;
}

.opinion {
  flex-direction: column;
  display: flex;
}

.opinion__author {
  flex-direction: column;
  order: 2;
  justify-content: center;
  align-items: center;
  margin-top: 3rem;
  display: flex;
}

.opinion__img {
  height: 70px;
  border-radius: 10px;
}

.opinion__name {
  text-align: center;
  margin-top: 1rem;
  font-size: 1.2rem;
  font-weight: bold;
}

.opinion__text {
  order: 1;
  align-items: center;
  padding-left: 0;
  font-size: 1.1rem;
  display: flex;
}

.carousel {
  width: 60vw;
  margin: 4rem auto 0;
  position: relative;
  overflow: hidden;
  box-shadow: 0 5px 15px #00000059;
}

.carousel__all {
  transition: transform .5s ease-in-out;
  display: inline-flex;
  transform: translateX(0);
}

.carousel__content {
  width: 60vw;
  text-align: center;
  background: #fff;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem 3rem 4rem;
  display: flex;
}

.carousel__text {
  color: var(--color-accent);
  font-size: 1rem;
  display: block;
}

.carousel__author {
  color: var(--color-accent);
  font-size: 1.8rem;
  font-weight: bold;
}

.carousel__course {
  color: var(--color-success-light);
  font-size: 1.4rem;
}

.carousel__arrow-right {
  position: absolute;
  top: 50%;
  right: 1rem;
}

.carousel__arrow-left {
  position: absolute;
  top: 50%;
  left: 1rem;
}

.carousel__arrow-right, .carousel__arrow-left {
  cursor: pointer;
  color: var(--color-secondary);
  opacity: .5;
  font-size: 2rem;
}

.carousel__dots {
  position: absolute;
  bottom: 1rem;
  left: 50%;
  transform: translateX(-50%);
}

.carousel__dot {
  width: 10px;
  height: 10px;
  background: var(--color-secondary);
  opacity: .5;
  border-radius: 50%;
  display: inline-block;
}

.carousel__dot--fill {
  opacity: 1;
}

@media screen and (min-width: 1024px) {
  .carousel__text {
    font-size: 1.3rem;
  }

  .carousel__content {
    padding: 2rem 6rem 4rem;
  }
}

@media screen and (min-width: 1125px) {
  .opinion {
    flex-direction: row;
  }

  .opinion__author {
    order: 1;
    margin-top: 0;
  }

  .opinion__text {
    order: 2;
    padding-left: 2rem;
  }
}

.path__container {
  text-align: center;
  flex-direction: column;
  align-items: center;
  display: flex;
}

.path__image {
  max-width: 100%;
  margin: 5rem 0 3rem;
}

.path__text {
  color: var(--color-secondary);
  letter-spacing: .1rem;
  font-size: 1.4rem;
}

@media screen and (min-width: 768px) {
  .path__text {
    color: var(--color-secondary);
    font-size: 1.6rem;
  }
}

.benefits__art {
  margin: 10rem 0;
}

.benefits__info {
  margin: 0 3rem;
}

.benefits__text {
  letter-spacing: .2rem;
  color: var(--color-accent);
  font-size: 1.6rem;
}

.benefits__image {
  max-width: 400px;
  order: 2;
  justify-self: center;
  margin-top: 3rem;
}

@media screen and (min-width: 768px) {
  .benefits__info, .benefits__image {
    order: 1;
  }
}

.summary {
  padding: 5rem 0 12rem;
}

.summary__list {
  font-size: 2rem;
  list-style: none;
}

.summary__item {
  padding: 1rem 2rem;
}

.summary__item:before {
  content: "✅";
}

.summary__text {
  color: #d7dadd;
  font-weight: bold;
}

.summary__price {
  color: var(--color-primary);
}

.summary__full-value {
  color: var(--color-primary);
  font-size: 2.1rem;
  text-decoration: line-through;
}

.summary__normal-value {
  color: var(--color-primary);
  font-size: 2.3rem;
}

.summary__full-price {
  color: var(--color-success);
  font-size: 2.7rem;
  font-weight: bold;
  text-decoration: underline;
}

.community {
  flex-direction: column;
  align-items: center;
  margin-bottom: 5rem;
  display: flex;
}

.community__icon {
  font-size: 5rem;
}

.community__count {
  font-size: 7rem;
  font-weight: bold;
}

.community__label {
  font-size: 1.4rem;
}

.community__icon--primary {
  color: var(--color-primary);
}

.community__icon--info {
  color: var(--color-info);
}

.community__icon--success {
  color: var(--color-success);
}

.quote__block {
  text-align: center;
  position: relative;
}

.quote__icon {
  opacity: .4;
  font-size: 10rem;
  position: absolute;
  top: -3rem;
  left: -3rem;
}

.quote__text {
  font-size: 2.2rem;
}

.quote__author {
  margin-top: 2rem;
  font-size: 1.8rem;
  font-style: italic;
  display: block;
}

@media screen and (min-width: 768px) {
  .quote__text {
    font-size: 4rem;
  }

  .quote__author {
    font-size: 2.4rem;
  }
}

.program__grid {
  gap: 5rem;
}

.program__image {
  max-width: 100%;
  padding-left: 3rem;
  padding-right: 3rem;
}

@media screen and (min-width: 768px) {
  .program__image {
    padding-left: 0;
    padding-right: 0;
  }
}

.example__grid {
  gap: 5rem;
}

.example__div {
  padding-left: 3rem;
  padding-right: 3rem;
}

.example__text {
  text-align: center;
  font-size: 1.6rem;
}

@media screen and (min-width: 768px) {
  .example__div {
    padding-left: 0;
    padding-right: 0;
  }

  .example__text {
    text-align: center;
    font-size: 1.9rem;
  }
}

.security__header {
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 3rem;
}

.security__text {
  text-align: center;
  font-size: 1.6rem;
}

.security__payment, .security__customer {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.security__image {
  width: 250px;
  margin: 5rem;
}

.faq__section {
  margin-top: 1rem;
}

.faq__text {
  color: var(--color-accent);
  font-size: 1.6rem;
}

.faq__email {
  color: var(--color-primary);
  font-weight: bold;
}

@media screen and (min-width: 768px) {
  .faq__text {
    color: var(--color-accent);
    font-size: 1.8rem;
  }
}

.footer {
  text-align: center;
  color: #fff;
  background: #000;
  padding: 2rem 0;
  font-size: 1.5rem;
}

.footer__link {
  color: #fff;
  text-decoration: none;
}

.footer__link:hover {
  color: var(--color-secondary);
}

.modal {
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: #0006;
  padding-top: 200px;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  overflow: auto;
}

.modal-content {
  width: 90%;
  max-width: 600px;
  background-color: #fff;
  border: 1px solid #888;
  margin: auto;
  padding: 5px 20px 40px;
  animation-name: animatetop;
  animation-duration: .4s;
  position: relative;
  box-shadow: 0 4px 8px #0003, 0 6px 20px #00000030;
}

@keyframes animatetop {
  from {
    opacity: 0;
    top: -300px;
  }

  to {
    opacity: 1;
    top: 0;
  }
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover, .close:focus {
  color: #000;
  cursor: pointer;
  text-decoration: none;
}

.modal__heading {
  text-align: center;
  margin: 5rem 3rem 0;
  font-size: 2rem;
  line-height: 1.5;
}

.modal__form {
  flex-direction: column;
  row-gap: 1rem;
  margin: 3rem 3rem 0;
  display: flex;
}

.modal__input {
  border: 1px solid var(--color-secondary);
  border-radius: 5px;
  padding: 1rem 2rem;
  font-size: 1.7rem;
}

.modal__submit {
  color: #fff;
  cursor: pointer;
  background: #28a745;
  border: 0;
  border-radius: 5px;
  padding: 1rem 2rem;
  font-size: 2.1rem;
}

.modal__submit:hover {
  background: #31c553;
}

.modal__info {
  color: #6c757d;
  text-align: center;
  margin: 2rem 5rem;
  font-size: 1.2rem;
  line-height: 1.1;
  display: block;
}

.modal__success {
  color: var(--color-primary);
  text-align: center;
  margin: 2rem 5rem;
  font-size: 1.3rem;
  font-weight: bold;
  line-height: 1.3;
  display: none;
}

.modal__icon {
  color: #000;
  margin-right: 1px;
}

.loading-overlay {
  width: 90%;
  height: 90%;
  z-index: 999;
  background: #ffffff4d;
  justify-content: center;
  align-items: center;
  display: none;
  position: absolute;
}

.loading-gif {
  z-index: 9999;
  width: 80px;
  height: 80px;
  border: 13px solid #f3f3f3;
  border-top-color: #3498db;
  border-radius: 50%;
  animation: .6s linear infinite spin;
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

@media screen and (min-width: 768px) {
  .modal-content {
    width: 600px;
  }

  .modal__heading {
    font-size: 3rem;
  }

  .modal__input {
    font-size: 2.1rem;
  }

  .modal__info {
    font-size: 1.3rem;
  }

  .modal__success {
    font-size: 1.4rem;
  }
}

.privace, .regulamin {
  margin-top: 10rem;
  margin-bottom: 10rem;
  font-size: 1.5rem;
}

.regulamin ul {
  list-style: none;
}

.privace h1, .regulamin h1 {
  text-align: center;
  margin-bottom: 4rem;
  font-size: 4rem;
}

.privace h2, .regulamin h2 {
  margin-top: 8rem;
  margin-bottom: 4rem;
  font-size: 3rem;
}

.regulamin h3 {
  margin-top: 8rem;
  margin-bottom: 4rem;
  font-size: 2.1rem;
}

/*# sourceMappingURL=index.38be3fd5.css.map */
